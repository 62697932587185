<template>
    <div>
        <top-header />

        <home-stage />

        <section-area content-position="left" background-color="white">
            <template slot="headline"> Online </template>
            <template slot="text">
                Mit dem Bilder-Uploadservice von COPPIO können Ihre Kunden
                einfach und bequem direkt über den Internetbrowser ihre Fotos
                hochladen – und das ganz ohne App-Anbindung und auf jedem
                Smartphone und Betriebssystem.
            </template>
            <template slot="button">
                <nuxt-link to="/online-shop" class="section__button">
                    Mehr erfahren
                </nuxt-link>
            </template>
            <template slot="media">
                <div class="section__image-container">
                    <img
                        v-lazy-load
                        src="~/assets/home/online.png"
                        alt="Online"
                        class="home-img-online"
                    />
                </div>
            </template>
        </section-area>

        <section-area content-position="right" background-color="grey">
            <template slot="headline"> Galerie </template>
            <template slot="text">
                Verkaufen Sie Ihre Fotos professionell mit COPPIO. Erstellen Sie
                ansprechende Bildergalerien, laden Sie Fotos direkt über den
                Browser hoch und setzen Sie individuelle Preise für maximale
                Flexibilität. Ideal für Fotofachgeschäfte und Fotografen.
            </template>
            <template slot="button">
                <nuxt-link to="/galerie" class="section__button">
                    Mehr erfahren
                </nuxt-link>
            </template>
            <template slot="media">
                <div class="section__image-container">
                    <img
                        v-lazy-load
                        src="~/assets/home/gallery.png"
                        alt="Galerie"
                        class="home-img-gallery"
                    />
                </div>
            </template>
        </section-area>

        <section-area
            content-position="center"
            image-container-position="top"
            background-color="white"
        >
            <template slot="headline"> Kiosk </template>
            <template slot="text">
                Mit dem COPPIO Kiosk-System bieten Sie Ihren Kunden ein
                einzigartiges lokales Bestellerlebnis. Die benutzerfreundliche
                Bestelloberfläche macht es einfach, Ihre Produkte zu
                durchsuchen, auszuwählen und zu bestellen, während Sie als
                Anbieter den Bestellvorgang nahtlos verwalten können.
            </template>
            <template slot="button">
                <nuxt-link to="/kiosk" class="section__button">
                    Mehr erfahren
                </nuxt-link>
            </template>
            <template slot="media">
                <div class="section__image-container">
                    <img
                        v-lazy-load
                        src="~/assets/home/kiosk.png"
                        alt="Kiosk"
                        class="home-img-kiosk"
                    />
                </div>
            </template>
        </section-area>

        <section-area
            content-position="center"
            image-container-position="bottom"
            background-color="grey"
        >
            <template slot="headline"> Ordermanager </template>
            <template slot="text">
                Mit dem COPPIO Ordermanager können Sie Ihre Aufträge schnell und
                einfach verwalten. Sie können Aufträge bequem einlesen und
                Rechnungen, Bons oder Label direkt ausdrucken. Dank der
                automatischen Übergabe an den Drucker oder das Labor sparen Sie
                Zeit und können sich auf das Wesentliche konzentrieren.
            </template>
            <template slot="button">
                <nuxt-link to="/ordermanager" class="section__button">
                    Mehr erfahren
                </nuxt-link>
            </template>
            <template slot="media">
                <div class="section__image-container">
                    <img
                        v-lazy-load
                        src="~/assets/ordermanager/screenshot-app.jpg"
                        alt="Ordermanager"
                        class="home-img-ordermanager"
                    />
                </div>
            </template>
        </section-area>

        <customers />

        <feedback-consumer />

        <bottom-footer />
    </div>
</template>

<script>
import TopHeader from '~/components/top-header.vue';
import HomeStage from '~/components/home-stage.vue';
import SectionArea from '~/components/section-area.vue';
import Customers from '~/components/customers.vue';
import FeedbackConsumer from '~/components/feedback-consumer.vue';
import BottomFooter from '~/components/bottom-footer.vue';

export default {
    components: {
        TopHeader,
        HomeStage,
        SectionArea,
        Customers,
        FeedbackConsumer,
        BottomFooter,
    },

    head() {
        return {
            title: 'COPPIO - Der digitale Rundumservice für Ihr Fotofachgeschäft / Fotostudio',
            meta: [
                {
                    name: 'description',
                    content:
                        'Entdecken Sie unsere digitalen Tools wie Bilderupload, Galeriefunktion (Fotografen), Cross-Selling, Kiosk und Ordermanager, um Kundenbindung, Reichweite und Umsatzwachstum zu fördern. Optimieren Sie Ihr Fotogeschäft mit unseren innovativen Lösungen.',
                },
                {
                    hid: 'og:url',
                    name: 'og:url',
                    content: 'https://www.coppio.de',
                },
                {
                    hid: 'og:type',
                    name: 'og:type',
                    content: 'website',
                },
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content:
                        'COPPIO - Der digitale Rundumservice für Ihr Fotofachgeschäft / Fotostudio',
                },
                {
                    hid: 'og:description',
                    name: 'og:description',
                    content:
                        'Entdecken Sie unsere digitalen Tools wie Bilderupload, Galeriefunktion (Fotografen), Cross-Selling, Kiosk und Ordermanager, um Kundenbindung, Reichweite und Umsatzwachstum zu fördern. Optimieren Sie Ihr Fotogeschäft mit unseren innovativen Lösungen.',
                },
                {
                    hid: 'og:image',
                    name: 'og:image',
                    content: 'https://www.coppio.de/share/home.jpg',
                },
                {
                    hid: 'twitter:card',
                    name: 'twitter:card',
                    content: 'summary_large_image',
                },
                {
                    hid: 'twitter:domain',
                    name: 'twitter:domain',
                    content: 'www.coppio.de',
                },
                {
                    hid: 'twitter:url',
                    name: 'twitter:url',
                    content: 'https://www.coppio.de',
                },
                {
                    hid: 'twitter:title',
                    name: 'twitter:title',
                    content:
                        'COPPIO - Der digitale Rundumservice für Ihr Fotofachgeschäft / Fotostudio',
                },
                {
                    hid: 'twitter:description',
                    name: 'twitter:description',
                    content:
                        'Entdecken Sie unsere digitalen Tools wie Bilderupload, Galeriefunktion (Fotografen), Cross-Selling, Kiosk und Ordermanager, um Kundenbindung, Reichweite und Umsatzwachstum zu fördern. Optimieren Sie Ihr Fotogeschäft mit unseren innovativen Lösungen.',
                },
                {
                    hid: 'twitter:image',
                    name: 'twitter:image',
                    content: 'https://www.coppio.de/share/home.jpg',
                },
            ],
        };
    },
};
</script>

<style lang="scss">
.home-img-online {
    width: 60%;
    height: auto;
    box-shadow: none !important;
    margin-top: -22px;
    margin-bottom: -30px;

    @include breakpoint(xs) {
        margin-left: -20px;
        margin-top: -35px;
        margin-bottom: -40px;
        width: calc(100% + 40px);
    }
}

.home-img-gallery {
    width: 60%;
    height: auto;
    box-shadow: none !important;
    margin-top: -22px;
    margin-bottom: -30px;

    @include breakpoint(xs) {
        margin-left: -20px;
        margin-top: -32px;
        margin-bottom: -42px;
        width: calc(100% + 40px);
    }
}

.home-img-kiosk {
    width: 60% !important;
    height: auto;
    box-shadow: none !important;
    margin-top: -30px;
    margin-bottom: -45px;

    @include breakpoint(xs) {
        margin-top: -47px;
        margin-bottom: -50px;
    }
}

.home-img-ordermanager {
    width: 80%;
    height: auto;
    border-radius: 3px;
    box-shadow: 0px 0px 30px rgba(54, 65, 75, 0.25);

    @include breakpoint(xs) {
        width: 60%;
    }
}
</style>
